<ngb-accordion #detailsAccordion="ngbAccordion" activeIds="ngb-panel-details" class="no-padding">
    <ngb-panel id="ngb-panel-details">
        <ng-template ngbPanelTitle>
            <h3 translate title="{{ title }}">{{ title }}</h3>
            <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
            <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
        </ng-template>
        <ng-template ngbPanelContent>
            <table class="table table-sm mb-0">
                <tbody>
                    <tr *ngFor="let object of objects">
                        <td>
                            <zx-status-full [model]="object"></zx-status-full>
                        </td>
                        <td>{{ object.name }}</td>
                        <td class="text-end">
                            <a href="javascript:void(0)" title="{{ 'VIEW' | translate }}" (click)="goto(objectsType, object)"
                                ><span>{{ "VIEW" | translate }}</span></a
                            >
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-template>
    </ngb-panel>
</ngb-accordion>
