import { Component, Input, inject } from "@angular/core";
import { Router } from "@angular/router";
import { urlBuilder } from "@zixi/shared-utils";
import { Constants } from "src/app/constants/constants";
import { TargetsService } from "../../targets/targets.service";
import { SearchResult, SEARCH_RESULT_OBJECT_TYPES } from "src/app/models/search";

@Component({
    selector: "app-search-result",
    templateUrl: "./search-result.component.html"
})
export class SearchResultComponent {
    @Input() objects: Exclude<SearchResult[keyof SearchResult], undefined>[number][];
    @Input() objectsType: keyof SearchResult;
    TITLES = {
        [SEARCH_RESULT_OBJECT_TYPES.FEEDERS]: "FEEDERS",
        [SEARCH_RESULT_OBJECT_TYPES.CLUSTERS]: "CLUSTERS",
        [SEARCH_RESULT_OBJECT_TYPES.RECEIVERS]: "RECEIVERS",
        [SEARCH_RESULT_OBJECT_TYPES.SOURCES]: "SOURCES",
        [SEARCH_RESULT_OBJECT_TYPES.CHANNELS]: "CHANNELS",
        [SEARCH_RESULT_OBJECT_TYPES.TARGETS]: "TARGETS",
        [SEARCH_RESULT_OBJECT_TYPES.REMOTE_ACCESS]: "REMOTE_ACCESS"
    };

    private router = inject(Router);
    private targetsService = inject(TargetsService);

    get title() {
        return this.TITLES[this.objectsType];
    }

    goto(type: keyof SearchResult, object: Exclude<SearchResult[keyof SearchResult], undefined>[number]) {
        switch (type) {
            case "feeders":
                this.router.navigate(urlBuilder.getRegularZecUrl(object.id, Constants.urls.feeders, object.name));
                break;
            case "clusters":
                this.router.navigate(urlBuilder.getRegularClusterUrl(object.id, object.name));
                break;
            case "receivers":
                this.router.navigate(urlBuilder.getRegularZecUrl(object.id, Constants.urls.receivers, object.name));
                break;
            case "remote_access":
                this.router.navigate([Constants.urls.remote_access, object.name]);
                break;
            case "channels":
                this.router.navigate(
                    urlBuilder.getRegularChannelUrl(
                        object.id,
                        object.type === "delivery" ? "pass-through" : object.type ? object.type : "mediaconnect",
                        object.name
                    )
                );
                break;
            case "targets":
                this.router.navigate(
                    urlBuilder.getRegularTargetUrl(object.id, this.targetsService.hackAPIType(object), object.name)
                );
                break;
            case "sources":
                if (object.type !== "to-mediaconnect")
                    this.router.navigate(urlBuilder.getRegularSourceUrl(object.id, object.name));
                else this.router.navigate([Constants.urls.sources, "mediaconnect", object.name]);
                break;
        }
    }
}
