<div class="d-flex justify-content-start" *ngIf="sources">
    <div *ngFor="let source of sources; let i = index" class="position-relative">
        <zx-status-icon
            class="me-1"
            icon="square"
            triggers="manual"
            container="body"
            [model]="source"
            [showTooltip]="false"
            [ngbPopover]="popoverContent"
            #popover="ngbPopover"
            (mouseenter)="popover.open()"
            (click)="popover.open()"
            (mouseleave)="onMouseLeave(popover)"
            (clickOutside)="onMouseLeave(popover)"
        ></zx-status-icon>
        <ng-template #popoverContent>
            <div
                class="thumbnail-wrapper"
                (clickOutside)="onMouseLeave(popover); isMouseInsidePopover = false"
                (mouseleave)="popover.close(); isMouseInsidePopover = false"
                (mouseenter)="isMouseInsidePopover = true"
            >
                <zx-source class="d-block mt-1 ms-1" [model]="source" [showLink]="true"></zx-source>
                <div class="d-block">
                    <!-- Thumbnail -->
                    <app-source-thumbnail [source]="source"></app-source-thumbnail>
                    <!-- Details -->
                    <div class="details-container">
                        <app-new-details
                            [details]="{
                                content: (source?.status?.bitrate | number : '1.0-0') || '-',
                                title: 'BITRATE' | translate
                            }"
                            [isBoldContent]="true"
                        ></app-new-details>
                        <hr class="m-0" />
                        <app-new-details
                            [details]="{
                                content: source?.status?.up_time ? (source.status.up_time | uptime) : '-',
                                title: ('UP_TIME' | translate) + '[HH:mm:ss]'
                            }"
                            [isBoldContent]="true"
                        ></app-new-details>
                    </div>
                </div>
                <!-- Warning -->
                <zx-active-states [showStatus]="false" [activeStates]="source.activeStates" [objectName]="source.name"></zx-active-states>
            </div>
        </ng-template>
    </div>
</div>
