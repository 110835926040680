import { Cluster } from "../pages/clusters/cluster";
import { Feeder } from "../pages/zecs/zecs/zec";
import { Receiver } from "../pages/zecs/zecs/zec";
import { MediaConnectFlow, MediaConnectSource, Source } from "./shared";
import {
    AdaptiveChannel,
    DeliveryChannel,
    RistTarget,
    UdpRtpTarget,
    RtmpPushTarget,
    ZixiPullTarget,
    ZixiPushTarget,
    PublishingTarget,
    SrtTarget
} from "../pages/channels/channel";
import { RemoteAccess } from "../pages/remote-access/remote-access";

export enum SEARCH_RESULT_OBJECT_TYPES {
    FEEDERS = "feeders",
    CLUSTERS = "clusters",
    RECEIVERS = "receivers",
    SOURCES = "sources",
    CHANNELS = "channels",
    TARGETS = "targets",
    REMOTE_ACCESS = "remote_access"
}

export type SearchResult = Partial<{
    [SEARCH_RESULT_OBJECT_TYPES.FEEDERS]: Feeder[];
    [SEARCH_RESULT_OBJECT_TYPES.CLUSTERS]: Cluster[];
    [SEARCH_RESULT_OBJECT_TYPES.RECEIVERS]: Receiver[];
    [SEARCH_RESULT_OBJECT_TYPES.SOURCES]: (Source | MediaConnectSource)[];
    [SEARCH_RESULT_OBJECT_TYPES.CHANNELS]: (AdaptiveChannel | DeliveryChannel | MediaConnectFlow)[];
    [SEARCH_RESULT_OBJECT_TYPES.TARGETS]: (
        | RistTarget
        | UdpRtpTarget
        | RtmpPushTarget
        | ZixiPullTarget
        | ZixiPushTarget
        | PublishingTarget
        | SrtTarget
    )[];
    [SEARCH_RESULT_OBJECT_TYPES.REMOTE_ACCESS]: RemoteAccess[];
}>;
