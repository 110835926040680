<div class="page-container">
    <div class="page-content">
        <!-- Title Bar -->
        <div class="title-bar">
            <div class="title">
                <h1 translate title="{{ 'SEARCH' | translate }}">SEARCH</h1>
            </div>
        </div>
        <div class="content-area">
            <div class="container-fluid">
                <!-- Search -->
                <div class="row">
                    <div class="col-12">
                        <div class="input-group mb-3">
                            <input
                                type="text"
                                class="form-control"
                                placeholder="{{ 'SEARCH' | translate }}..."
                                [(ngModel)]="searchVal"
                                aria-label="Search"
                                aria-describedby="search-button"
                                (keyup)="$event.keyCode === 13 && search()"
                            />
                            <button class="btn btn-outline-primary" type="button" id="search-button" (click)="search()" [disabled]="!searchVal">
                                <fa-icon class="me-1" icon="search" size="sm"></fa-icon>{{ "SEARCH" | translate }}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="loading">
                    <!-- Loading -->
                    <div class="col-12">
                        <content-loading-animation class="block"></content-loading-animation>
                    </div>
                </div>
                <!-- Search Results -->
                <div class="details-content-accordions">
                    <div class="row" *ngIf="!loading && isEmptyObject(result)">
                        <div class="col-12">
                            {{ "NO_RESULTS" | translate }}
                        </div>
                    </div>

                    <div class="row" *ngIf="!loading && !isEmptyObject(result)">
                        <div *ngIf="result.channels?.length" class="col-12 col-xxl-6">
                            <app-search-result [objectsType]="SEARCH_RESULT_OBJECT_TYPES.CHANNELS" [objects]="result.channels"></app-search-result>
                        </div>
                        <div *ngIf="result.clusters?.length" class="col-12 col-xxl-6">
                            <app-search-result [objectsType]="SEARCH_RESULT_OBJECT_TYPES.CLUSTERS" [objects]="result.clusters"></app-search-result>
                        </div>
                        <div *ngIf="result.feeders?.length" class="col-12 col-xxl-6">
                            <app-search-result [objectsType]="SEARCH_RESULT_OBJECT_TYPES.FEEDERS" [objects]="result.feeders"></app-search-result>
                        </div>
                        <div *ngIf="result.receivers?.length" class="col-12 col-xxl-6">
                            <app-search-result [objectsType]="SEARCH_RESULT_OBJECT_TYPES.RECEIVERS" [objects]="result.receivers"></app-search-result>
                        </div>
                        <div *ngIf="result.remote_access?.length" class="col-12 col-xxl-6">
                            <app-search-result [objectsType]="SEARCH_RESULT_OBJECT_TYPES.REMOTE_ACCESS" [objects]="result.remote_access"></app-search-result>
                        </div>
                        <div *ngIf="result.sources?.length" class="col-12 col-xxl-6">
                            <app-search-result [objectsType]="SEARCH_RESULT_OBJECT_TYPES.SOURCES" [objects]="result.sources"></app-search-result>
                        </div>
                        <div *ngIf="result.targets?.length" class="col-12 col-xxl-6">
                            <app-search-result [objectsType]="SEARCH_RESULT_OBJECT_TYPES.TARGETS" [objects]="result.targets"></app-search-result>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
