import { Component, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";

import { SearchService } from "../../services/search.service";
import { SearchResult, SEARCH_RESULT_OBJECT_TYPES } from "../../models/search";
import { TitleService } from "../../services/title.service";

@Component({
    templateUrl: "./search.component.html"
})
export class SearchComponent implements OnDestroy {
    loading = true;
    result: SearchResult;
    SEARCH_RESULT_OBJECT_TYPES = SEARCH_RESULT_OBJECT_TYPES;
    private searchFilter: string;
    private searchSubscription: Subscription;

    constructor(
        private route: ActivatedRoute,
        private searchService: SearchService,
        private titleService: TitleService
    ) {
        this.titleService.setTitle("SEARCH", "");

        // The ActivatedRoute dies with the routed component and so the subscription dies with it.
        this.searchSubscription = this.route.queryParamMap.subscribe(params => {
            this.searchVal = params.get("q") ?? "";
            this.search();
        });
    }

    set searchVal(v: string) {
        this.searchFilter = v;

        if (this.searchFilter.length >= 3) this.search();
    }
    get searchVal(): string {
        return this.searchFilter;
    }

    ngOnDestroy() {
        this.searchSubscription.unsubscribe();
    }

    search() {
        this.loading = true;
        this.searchService.search(this.searchVal.trim()).then((result: SearchResult) => {
            this.result = result;
            this.loading = false;
        });
    }

    isEmptyObject(obj: unknown) {
        return obj && Object.keys(obj).length === 0;
    }
}
