import { inject, OnInit, ViewChild } from "@angular/core";
import { Component } from "@angular/core";
import { AbstractControl, FormBuilder, FormControl, Validators } from "@angular/forms";
import _ from "lodash";
import { Constants } from "src/app/constants/constants";
import { LiveEventsService } from "../../live-events.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { LiveEvent } from "../events/liveevent";
import { TourService } from "ngx-ui-tour-md-menu";
import { TourSteps } from "src/app/constants/tour-steps";
import { LiveEventStageTableComponent } from "../../components/live-event-stage-table/live-event-stage-table.component";
import { LiveEventClipTableComponent } from "../../components/live-event-clip-table/live-event-clip-table.component";
import { SharedService } from "src/app/services/shared.service";
import { firstValueFrom } from "rxjs";
import { FailoverChannel } from "src/app/pages/channels/channel";
import { Tag } from "src/app/models/shared";

export type Stage = {
    name: string;
    force_channel_slate: boolean;
    clip_id: string | null;
    auto_mode: boolean;
    actual_time?: Date;
    scheduled_time: Date;
};

@Component({
    selector: "app-live-event-form",
    templateUrl: "./live-event-form.component.html",
    styleUrls: ["./live-event-form.component.scss"]
})
export class LiveEventFormComponent implements OnInit {
    @ViewChild(LiveEventStageTableComponent) stagesTable: LiveEventStageTableComponent;
    @ViewChild(LiveEventClipTableComponent) clipsTable: LiveEventClipTableComponent;

    selectModeControl = new FormControl<"Auto" | "Manual">("Auto", []);

    private formBuilder = inject(FormBuilder);

    form = this.formBuilder.group({
        name: ["", [Validators.required, Validators.minLength(2)]],
        tags: [[] as Tag[], [Validators.required]],
        failoverChannels: [[] as FailoverChannel[], [Validators.required]],
        disableChannel: [true]
    });

    loading = true;
    isEdit = false;
    isClone = false;
    options = ["Auto", "Manual"];
    exitUrl = Constants.urls.liveevents;
    isSubmitted = false;
    isInvalidSubmit = false;
    saving = false;
    action = "create";
    liveEventId: number;
    liveEvent: LiveEvent;
    stages: Stage[] = [
        {
            name: null,
            force_channel_slate: false,
            clip_id: null,
            auto_mode: false,
            scheduled_time: null
        },
        {
            name: "off",
            force_channel_slate: false,
            clip_id: null,
            auto_mode: true,
            scheduled_time: null
        }
    ];
    clips: { name: string; url: string }[] = [];

    private tourSteps = TourSteps.eventForm;
    private liveEventsService = inject(LiveEventsService);
    private router = inject(Router);
    private route = inject(ActivatedRoute);
    private mixpanelService = inject(MixpanelService);
    private tourService = inject(TourService);
    private sharedService = inject(SharedService);

    async ngOnInit() {
        const params = this.route.snapshot.params;
        this.liveEventId = parseInt(params.id, 10);
        this.action = this.router.url.split("/")[3];
        if (!this.action) {
            this.tourService.initialize(this.tourSteps);
            this.loading = false;
            return;
        }
        this.isEdit = this.action === "edit";
        this.isClone = this.action === "clone";

        if (this.liveEventId) {
            this.liveEvent = this.liveEventsService.getCachedLiveEvent(this.liveEventId);
            if (!this.liveEvent)
                this.liveEvent = await firstValueFrom(this.liveEventsService.refreshLiveEvent(this.liveEventId));

            this.form.patchValue({
                failoverChannels: this.liveEvent.failoverChannels,
                tags: this.liveEvent.resourceTags,
                disableChannel: this.liveEvent.disable_channel_after_done
            });

            if (!this.isClone) {
                this.form.patchValue({
                    name: this.liveEvent.name
                });
            }

            this.clips = [...this.liveEvent.clips];

            const actions = this.liveEvent.actions.map(action => ({
                ...action,
                scheduled_time: this.isEdit || this.isClone ? new Date(action.scheduled_time) : undefined,
                actual_time: this.isEdit && action.actual_time ? new Date(action.actual_time) : undefined
            }));

            this.stages = actions.map(this.convertClipIdToName);
        }
        this.loading = false;
    }

    async onSubmit() {
        this.saving = true;
        const formControls = {
            ...this.form.controls,
            ...this.stagesTable.form.controls,
            ...this.clipsTable.form.controls
        };
        this.isInvalidSubmit = false;
        for (const field in formControls) {
            const control: AbstractControl = formControls[field];
            if (control.errors != null) {
                this.isInvalidSubmit = true;
                this.saving = false;
                return;
            }
        }

        const model = {
            name: formControls.name.value,
            resource_tag_ids: _.map(formControls.tags.value, "id"),
            failover_channel_ids: _.map(formControls.failoverChannels.value, "id"),
            disable_channel_after_done: !!formControls.disableChannel.value,
            stages: this.stages,
            clips: this.clips.filter(clip => !!clip.name && !!clip.url)
        };

        if (this.isEdit) {
            const objects = {
                resource_tag_ids: { objectsKey: "resourceTags", valuePath: "id" },
                failover_channel_ids: { objectsKey: "failoverChannels", valuePath: "id" }
            };
            const diff = this.sharedService.getZixiObjDiff(
                {
                    ...model
                },
                {
                    ...this.liveEvent,
                    disable_channel_after_done: !!this.liveEvent.disable_channel_after_done,
                    stages: this.liveEvent.actions.map(this.convertClipIdToName)
                },
                null,
                objects
            );
            if (this.sharedService.isEmptyObject(diff)) {
                this.saving = false;
                return this.router.navigate([this.exitUrl]);
            }
            if (!("stages" in diff || "clips" in diff)) {
                delete model.stages;
                delete model.clips;
            }
        }

        let result;
        if (this.isEdit) {
            result = await this.liveEventsService.updateLiveEvent(this.liveEvent.id, model);
        } else {
            result = await this.liveEventsService.addLiveEvent(model);
        }
        if (result) {
            this.mixpanelService.sendEvent(`${this.action} live-event`);
            this.saving = false;
            this.router.navigate([this.exitUrl]);
        }
        this.saving = false;
    }

    clipChanged(clips: { name: string; url: string }[]) {
        this.clips = [...clips];

        // sync deleted clips on stages table
        for (const stage of this.stages) {
            if (!this.clips.map(clip => clip.name).includes(stage.clip_id)) {
                stage.clip_id = null;
            }
        }
    }

    private convertClipIdToName = <T>(stage: T & { clip_id: string | number }) => ({
        // convert clip_id to a url
        ...stage,
        clip_id:
            typeof stage.clip_id === "number"
                ? this.liveEvent.clips.find(clip => clip.id === (stage.clip_id as unknown as number)).name
                : stage.clip_id
    });
}
