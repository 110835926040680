import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { ActivatedRoute } from "@angular/router";
import { BehaviorSubject, firstValueFrom, merge, of, pipe, Subject, throwError } from "rxjs";
import { auditTime, catchError, filter, map, shareReplay, skip, switchMap, take, takeUntil, tap } from "rxjs/operators";
import * as _ from "lodash";

import { Constants } from "src/app/constants/constants";
import { AnyTarget, DeliveryChannel, FailoverChannel } from "src/app/pages/channels/channel";
import { LiveEvent, LiveEventActionFront } from "../events/liveevent";
import { Broadcaster, TargetsSummary, Source } from "src/app/models/shared";

import { AuthService } from "src/app/services/auth.service";
import { ChannelsService } from "src/app/pages/channels/channels.service";
import { SourcesService } from "src/app/pages/sources/sources.service";
import { BroadcastersService } from "src/app/components/broadcasters/broadcasters.service";
import { TargetsService } from "src/app/pages/targets/targets.service";
import {
    PushUpdatesService,
    CHANNELS,
    Publication,
    PUB_TYPES,
    REFRESH_RATE_LIMIT
} from "src/app/services/push-updates.service";

export interface LiveEventDetails {
    liveEvent: LiveEvent;
    actions: (LiveEventActionFront & { clipName?: string | null })[];
    failoverChannels: FailoverChannelDetails[];
}

export interface FailoverChannelDetails {
    data: FailoverChannel;
    sources: SourcesDetails;
    broadcaster: Broadcaster;
    targets: TargetsDetails;
    lockedSource: number | null;
}

interface SourcesDetails {
    failover: Source;
    slate: Source;
    primary: Source;
    secondary: Source;
    sources: Source[];
}

export interface TargetsDetails {
    deliveryChannel: DeliveryChannel;
    summary: TargetsSummary;
    targets: AnyTarget[];
}

export const LIVE_EVENT_DELETED = PUB_TYPES.DELETED;

@Injectable({
    providedIn: "root"
})
export class LiveEventDetailsService {
    private readonly isLoggedOut$ = this.authService.isLoggedIn.pipe(filter(isLoggedIn => !isLoggedIn));
    private liveEventId$ = this.route.paramMap.pipe(
        map(map => map.get("id")),
        shareReplay(1)
    );
    private manualRefresh$ = new Subject<void>();
    private isFetchingDataBS$ = new BehaviorSubject<boolean>(false);
    public isFetchingData$ = this.isFetchingDataBS$.asObservable();
    public liveEventDetails$ = this.liveEventId$.pipe(
        takeUntil(this.isLoggedOut$),
        switchMap(liveEventId => {
            const pushUpdatesRefresh$ = this.pushUpdatesService
                .subscribeChannel({ name: CHANNELS.live_events, objectId: parseInt(liveEventId) })
                .pipe(
                    switchMap((pub: Publication) => {
                        const isLiveEventDeleted = pub.publicationType === PUB_TYPES.DELETED;
                        return isLiveEventDeleted
                            ? throwError(() => new Error(LIVE_EVENT_DELETED))
                            : of(pub).pipe(auditTime(REFRESH_RATE_LIMIT));
                    })
                );
            const refresh$ = merge(this.liveEventId$, this.manualRefresh$, pushUpdatesRefresh$);
            return refresh$.pipe(map(() => liveEventId));
        }),
        tap(() => this.isFetchingDataBS$.next(true)),
        switchMap(async liveEventId => await this.getLiveEventDetails(liveEventId)),
        tap(() => this.isFetchingDataBS$.next(false)),
        shareReplay({ bufferSize: 1, refCount: true })
    );
    /**
     * Emits a boolean to indicate whether the dashboard action succeeded.
     * When it does, it emits after receiving the push update and refreshing the data.
     * When action fails, it emits immediately
     */
    private actionResIndicatorWithRefreshTimePipe = pipe(
        switchMap((response: { success: boolean }) =>
            response.success ? this.liveEventDetails$.pipe(skip(1)) : throwError(() => new Error("Action Failed"))
        ),
        take(1),
        map(() => true),
        catchError(() => of(false))
    );

    constructor(
        private route: ActivatedRoute,
        private authService: AuthService,
        private http: HttpClient,
        private channelsService: ChannelsService,
        private sourcesService: SourcesService,
        private broadcastersService: BroadcastersService,
        private targetsService: TargetsService,
        private pushUpdatesService: PushUpdatesService
    ) {}

    public toggleIsSlateLocked(liveEvent: LiveEvent) {
        const isLocked = !liveEvent.is_slate_locked;
        const response$ = this.http.put<{ success: boolean }>(
            Constants.apiUrl + Constants.apiUrls.liveevents + "/" + liveEvent.id + "/lock",
            {
                is_slate_locked: isLocked
            }
        );

        return response$.pipe(this.actionResIndicatorWithRefreshTimePipe).toPromise();
    }

    getActiveAction(liveEvent: LiveEvent): LiveEventActionFront | null {
        return liveEvent.actions.find(action => action.id === liveEvent.stage_id) as LiveEventActionFront | null;
    }

    public startNextStage(param: LiveEventDetails | LiveEvent, keepDuration: boolean) {
        let liveEvent: LiveEvent;
        if ("liveEvent" in param) {
            liveEvent = param.liveEvent;
        } else {
            liveEvent = param;
        }
        const activeAction = this.getActiveAction(liveEvent);
        if (activeAction?.name === "off") {
            return false;
        }

        const nextAction = this.nextStageAction(liveEvent.actions, activeAction?.id);
        const nextActionId = nextAction?.id ?? liveEvent.actions.find(action => action.name === "off")?.id;

        let params = new HttpParams();
        params = params.append("keep_duration", keepDuration);

        if (nextActionId) {
            const response$ = this.http
                .post<{ success: boolean }>(
                    Constants.apiUrl + Constants.apiUrls.liveevents + "/actions/" + nextActionId,
                    {},
                    { params }
                )
                .pipe(
                    tap(() => this.updateLiveEvent()),
                    map(response => response.success)
                );
            return firstValueFrom(response$);
        } else {
            return false;
        }
    }

    public endEvent(param: LiveEventDetails | LiveEvent) {
        const liveEvent = "liveEvent" in param ? param.liveEvent : param;
        const activeAction = this.getActiveAction(liveEvent);
        if (activeAction?.name === "off") return false;

        const offActionId = liveEvent.actions.find(action => action.name === "off").id;
        const response$ = this.http
            .post<{ success: boolean }>(Constants.apiUrl + Constants.apiUrls.liveevents + "/actions/" + offActionId, {})
            .pipe(
                tap(() => this.updateLiveEvent()),
                map(response => response.success)
            );
        return firstValueFrom(response$);
    }

    public updateLiveEvent() {
        this.manualRefresh$.next();
    }

    public nextStageAction(actions: LiveEventActionFront[], currentStageId?: number) {
        if (!currentStageId) return actions[0];
        const currentStageIndex = actions.findIndex(action => action.id === currentStageId);
        if (currentStageIndex + 1 === actions.length) return undefined;
        return actions[currentStageIndex + 1];
    }

    isEventOff(liveEvent: LiveEvent) {
        return this.getActiveAction(liveEvent)?.name === "off";
    }

    isEventPending(liveEvent: LiveEvent) {
        return !liveEvent.stage_id;
    }

    isEventActive(liveEvent: LiveEvent) {
        return !this.isEventOff(liveEvent) && !this.isEventPending(liveEvent);
    }

    private async getLiveEventDetails(liveEventId: string): Promise<LiveEventDetails> {
        let liveEventDetails: LiveEventDetails = null;
        try {
            const liveEventPromise = this.http
                .get<{ result: LiveEvent; success: boolean }>(
                    Constants.apiUrl + Constants.apiUrls.liveevents + "/" + liveEventId
                )
                .toPromise();
            const actionsPromise = this.http
                .get<{ result: LiveEventActionFront[]; success: boolean }>(
                    Constants.apiUrl + Constants.apiUrls.liveevents + "/" + liveEventId + "/actions"
                )
                .toPromise();
            const [{ result: liveEvent }, { result: actions }] = await Promise.all([liveEventPromise, actionsPromise]);

            actions.map((action: LiveEventActionFront & { clipName: string }) => {
                let clipName = null;
                const stage = liveEvent.actions.find(stage => stage.name === action.name);
                if (stage?.clip_id) {
                    clipName = liveEvent.clips.find(clip => clip.id === (stage.clip_id as unknown as number)).name;
                }

                action.clipName = clipName;
            });

            const failoverChannels = await this.getFailoverChannels(liveEvent);

            liveEventDetails = {
                liveEvent: liveEvent,
                actions,
                failoverChannels
            };
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        }
        return liveEventDetails;
    }

    private async getFailoverChannels(liveEvent: LiveEvent): Promise<FailoverChannelDetails[]> {
        const failoverChannelsDetailsPromises = liveEvent.failoverChannels.map(failoverChannel => {
            return this.getFailoverChannelDetails(failoverChannel);
        });

        return await Promise.all(failoverChannelsDetailsPromises);
    }

    private async getFailoverChannelDetails(failoverChannel: FailoverChannel): Promise<FailoverChannelDetails> {
        const sourcesPromise = this.getSources(failoverChannel.failover_source_id);
        const targetsPromise = this.getTargets(failoverChannel.delivery_channel_id);
        const [sources, targets] = await Promise.all([sourcesPromise, targetsPromise]);
        const broadcaster = sources.failover?.activeBroadcasterObjects?.bx_id
            ? await this.broadcastersService
                  .refreshBroadcaster(sources.failover.activeBroadcasterObjects.bx_id, true)
                  .toPromise()
            : null;

        return {
            data: failoverChannel,
            lockedSource:
                failoverChannel.failoverSource.failoverSources.find(source => source.locked_source)?.source_id || null,
            sources,
            broadcaster,
            targets
        };
    }

    private async getSources(failoverSourceId: number): Promise<SourcesDetails> {
        const sourcesData = {
            failover: null,
            sources: [],
            slate: null,
            primary: null,
            secondary: null
        };

        if (!failoverSourceId) {
            throw new Error("Sources data unavailable");
        }

        sourcesData.failover = await this.sourcesService.refreshSource(failoverSourceId, true).toPromise();

        if (!_.isArray(sourcesData.failover?.failoverSources)) {
            throw new Error("Sources data unavailable");
        }

        sourcesData.failover.failoverSources.forEach(failoverComponentSource => {
            if (failoverComponentSource.priority === 0) {
                sourcesData.slate = failoverComponentSource.source;
            }
            if (failoverComponentSource.priority === 1) {
                sourcesData.secondary = failoverComponentSource.source;
            }
            if (failoverComponentSource.priority === 2) {
                sourcesData.primary = failoverComponentSource.source;
            }
        });

        sourcesData.sources = sourcesData.failover.failoverSources
            .sort((a, b) => b.priority - a.priority)
            .map(fs => fs.source);

        return sourcesData;
    }

    private async getTargets(deliveryChannelId: number): Promise<TargetsDetails> {
        const targetsData = {
            deliveryChannel: null,
            summary: null,
            targets: null
        };
        const deliveryChannel = (await this.channelsService.getDeliveryChannel(deliveryChannelId)) as DeliveryChannel;

        if (!deliveryChannel) {
            throw new Error("Targets data unavailable");
        }

        targetsData.deliveryChannel = deliveryChannel;
        targetsData.summary = deliveryChannel.targetsSummary;
        const targets = [
            ...(deliveryChannel.zixiPull || []),
            ...(deliveryChannel.zixiPush || []),
            ...(deliveryChannel.rtmpPush || []),
            ...(deliveryChannel.udpRtp || []),
            ...(deliveryChannel.rist || []),
            ...(deliveryChannel.srt || [])
        ];

        targetsData.targets = targets.map(target => {
            if (!target.deliveryChannel) target.deliveryChannel = deliveryChannel;
            return this.targetsService.prepTarget(target);
        });

        return targetsData;
    }
}
