import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { FontAwesome } from "./../../../modules/font-awesome/font-awesome.module";
import { SharedModule } from "./../../../modules/shared/shared.module";

import { ModalService } from "./modal.service";
import { ConfirmDialogComponent } from "./confirm-dialog/confirm-dialog.component";
import { MultipleConfirmDialogComponent } from "./multiple-confirm-dialog/multiple-confirm-dialog.component";
import { SingleDatepickerDialogComponent } from "./single-datepicker-dialog/single-datepicker-dialog.component";
import { AssignTargetDialogComponent } from "./assign-target-dialog/assign-target-dialog.component";
import { SwitchChannelDialogComponent } from "./switch-channel-dialog/switch-channel-dialog.component";
import { GenerateReportDialogComponent } from "./generate-report-dialog/generate-report-dialog.component";
import { ConfirmNavigationDialogComponent } from "./confirm-navigation-dialog/confirm-navigation-dialog.component";
import { AddIncidentDialogComponent } from "./add-incident-dialog/add-incident-dialog.component";
import { MultipleEditDialogComponent } from "./multiple-edit-dialog/multiple-edit-dialog.component";
import { ErrorDialogComponent } from "./error-dialog/error-dialog.component";
import { AgentZInstallConfirmationComponent } from "./agentz-install-confirm/agentz-install-confirm-dialog.component";
import { RootCauseAttributionDialogComponent } from "./root-cause-attribution-dialog/root-cause-attribution-dialog.component";
import { SharePageDialogComponent } from "./share-page-dialog/share-page-dialog.component";
import { ClusterRedirectionRuleDialogComponent } from "./cluster-redirection-rule-dialog/cluster-redirection-rule-dialog.component";
import { DeleteDisableBroadcasterDialogComponent } from "./delete-disable-broadcaster-dialog/delete-disable-broadcaster-dialog.component";
import { EditTagsDialogComponent } from "./edit-tags-dialog/edit-tags-dialog.component";
import { DisasterRecoveryDialogComponent } from "./disaster-recovery-dialog/disaster-recovery-dialog.component";
import { LiveEventsConfirmationDialogComponent } from "./live-events-confirmation-dialog/live-events-confirmation-dialog.component";
import { StartNextLiveEventStageDialogComponent } from "./start-next-live-event-stage-dialog/start-next-live-event-stage-dialog.component";

@NgModule({
    imports: [SharedModule, FormsModule, NgbModule, NgSelectModule, FontAwesome],
    declarations: [
        ConfirmDialogComponent,
        MultipleConfirmDialogComponent,
        SingleDatepickerDialogComponent,
        AssignTargetDialogComponent,
        SwitchChannelDialogComponent,
        GenerateReportDialogComponent,
        ConfirmNavigationDialogComponent,
        AddIncidentDialogComponent,
        MultipleEditDialogComponent,
        ErrorDialogComponent,
        AgentZInstallConfirmationComponent,
        RootCauseAttributionDialogComponent,
        SharePageDialogComponent,
        ClusterRedirectionRuleDialogComponent,
        DeleteDisableBroadcasterDialogComponent,
        EditTagsDialogComponent,
        DisasterRecoveryDialogComponent,
        LiveEventsConfirmationDialogComponent,
        StartNextLiveEventStageDialogComponent
    ],
    providers: [ModalService]
})
export class ModalModule {}
