import { Component, ComponentRef, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { filter, Subscription } from "rxjs";
import { TableSchema } from "../../table-list/table-list.component";
import { TranslateService } from "@ngx-translate/core";
import { ZxLiveEventNameComponent } from "../../zx-live-event-name/zx-live-event-name.component";
import { ZxStatusFullComponent } from "../../zx-status-full/zx-status-full.component";
import { assignComponentsLiveEventAdapter } from "../../zx-live-event-name/zx-live-event-name.table-adapter";
import { assignComponentsStatusInputsFactory } from "../../zx-status-full/zx-status-full.table-adapter";
import { KeyMap } from "src/app/models/shared";
import { LiveEvent } from "src/app/modules/live-events/pages/events/liveevent";
import { ZxNgbHighlightComponent } from "../../zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "../../zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { EventFrontData } from "src/app/modules/live-events/pages/events/live-events-list/live-events-list.component";
import { LiveEventActions } from "../../zx-action-buttons/zx-action-buttons.component";
import { SpinnerAnimationInlineComponent } from "../../spinner-animation-inline/spinner-animation-inline.component";
import { LiveEventDetailsService } from "src/app/modules/live-events/pages/event-details/live-event-details.service";

@Component({
    selector: "app-live-event-confirmation-dialog",
    templateUrl: "./live-events-confirmation-dialog.component.html"
})
export class LiveEventsConfirmationDialogComponent implements OnInit, OnDestroy {
    @Input() liveEvents: LiveEvent[];
    @Input() actionMode: LiveEventActions;

    liveEventActions = LiveEventActions;

    loading = false;
    disableSubmit = false;
    selectedLiveEvents: LiveEvent[] = [];
    titles = {
        [LiveEventActions.EndEvent]: `${this.translateService.instant("END")} ${this.translateService.instant(
            "EVENTS"
        )}`,
        [LiveEventActions.NextStage]: `${this.translateService.instant("START")} ${this.translateService.instant(
            "NEXT_STAGE"
        )}`
    };

    private routeSubscription: Subscription;

    constructor(
        public activeModal: NgbActiveModal,
        private router: Router,
        private translateService: TranslateService,
        private liveEventDetailsService: LiveEventDetailsService
    ) {
        this.routeSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe(() => {
                this.activeModal.close();
            });
    }

    LiveEventsTableColumnsSchema: TableSchema<KeyMap<LiveEvent>>[] = [
        {
            header: this.translateService.instant("NAME"),
            columnDef: "name",
            width: 160,
            visible: true,
            sticky: 1,
            component: ZxLiveEventNameComponent,
            assignComponentsInputs: assignComponentsLiveEventAdapter(false),
            textValue: row => row.name
        },
        {
            header: this.translateService.instant("STATUS"),
            columnDef: "status",
            width: 100,
            visible: true,
            component: ZxStatusFullComponent,
            assignComponentsInputs: assignComponentsStatusInputsFactory()
        },
        {
            header: this.translateService.instant("STAGE"),
            columnDef: "stage",
            visible: true,
            hideFromColumnChooser: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<LiveEvent & EventFrontData>(
                row => row.stageName,
                row => row.stageName,
                () => true
            )
        },
        {
            header: this.translateService.instant("NEXT_STAGE"),
            columnDef: "next_stage",
            visible: true,
            hideFromColumnChooser: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<LiveEvent & EventFrontData>(
                row => row.nextStageName,
                row => row.nextStageName,
                row => row.nextStageName !== "off"
            )
        },
        {
            header: this.translateService.instant("SLATED"),
            columnDef: "slate",
            visible: true,
            hideFromColumnChooser: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<LiveEvent & EventFrontData>(
                row =>
                    row.is_slate_locked ? this.translateService.instant("YES") : this.translateService.instant("NO"),
                row =>
                    row.is_slate_locked ? this.translateService.instant("YES") : this.translateService.instant("NO"),
                () => true
            )
        },
        {
            header: this.translateService.instant("LOADING"),
            columnDef: "rowLoading",
            visible: true,
            hideFromColumnChooser: true,
            hideColumnName: true,
            width: 70,
            component: SpinnerAnimationInlineComponent,
            assignComponentsInputs: (compRef: ComponentRef<SpinnerAnimationInlineComponent>, row) => {
                const statusComponentInstance = compRef.instance;
                statusComponentInstance.spinner = row._frontData.rowLoading;
                statusComponentInstance.placeholderText =
                    row._frontData.rowSuccess === true
                        ? this.translateService.instant("SUCCESS")
                        : row._frontData.rowSuccess === false
                        ? this.translateService.instant("FAILED")
                        : "";
            }
        }
    ];

    private refreshTable() {
        this.liveEvents = this.liveEvents.map(e => ({ ...e }));
    }

    private selectAll() {
        this.selectedLiveEvents = this.liveEvents;
    }

    async ngOnInit() {
        this.selectAll();
    }

    async ngOnDestroy() {
        this.routeSubscription.unsubscribe();
    }

    async submit(keepDuration?: boolean) {
        this.disableSubmit = true;
        const actionPromises = [];
        for (const row of this.selectedLiveEvents) {
            if (row._frontData.rowSuccess === true) continue;
            row._frontData.rowLoading = true;

            this.refreshTable();

            const actionPromise = this.getLiveEventAction(row, keepDuration)
                .then(result => (row._frontData.rowSuccess = !!result))
                .catch(() => (row._frontData.rowSuccess = false))
                .finally(() => (row._frontData.rowLoading = false));
            actionPromises.push(actionPromise);
        }

        Promise.all(actionPromises).finally(() => {
            this.refreshTable();
            this.disableSubmit = false;
        });
    }

    private async getLiveEventAction(liveEvent: LiveEvent, keepDuration?: boolean): Promise<boolean> {
        if (this.actionMode === LiveEventActions.EndEvent) {
            return this.liveEventDetailsService.endEvent(liveEvent);
        }
        if (this.actionMode === LiveEventActions.NextStage) {
            return this.liveEventDetailsService.startNextStage(liveEvent, keepDuration);
        }
    }
}
