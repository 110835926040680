import { DatePipe } from "@angular/common";
import { Component, ComponentRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivationEnd, Router, RouterOutlet } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import _ from "lodash";
import { BehaviorSubject, firstValueFrom, Observable, of, Subscription, interval, forkJoin } from "rxjs";
import { auditTime, filter, switchMap, take } from "rxjs/operators";
import { ModalService } from "src/app/components/shared/modals/modal.service";
import { Constants } from "src/app/constants/constants";
import { KeyMap, Tag, UserPermissions } from "src/app/models/shared";
import { UsersService } from "src/app/pages/account-management/users/users.service";
import { StatusTextPipe } from "src/app/pipes/status-text.pipe";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { SharedService } from "src/app/services/shared.service";
import { LiveEventsService } from "../../../live-events.service";
import { LiveEvent } from "../liveevent";
import { DesnakePipe } from "src/app/pipes/desnake.pipe";
import { STAGE } from "@zixi/models";
import { LiveEventDetailsService } from "../../event-details/live-event-details.service";
import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { ZxStatusFullComponent } from "src/app/components/shared/zx-status-full/zx-status-full.component";
import { assignComponentsStatusInputsFactory } from "src/app/components/shared/zx-status-full/zx-status-full.table-adapter";
import { assignDateTimeDisplayInputsFactory } from "src/app/components/shared/zx-date-time-display/zx-date-time-display.table-adapter";
import { ZxLiveEventNameComponent } from "src/app/components/shared/zx-live-event-name/zx-live-event-name.component";
import { assignComponentsLiveEventAdapter } from "src/app/components/shared/zx-live-event-name/zx-live-event-name.table-adapter";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { ZxEditTableRowButtonsComponent } from "src/app/components/shared/zx-edit-table-row-buttons/zx-edit-table-row-buttons.component";
import { assignEditTableRowInputsFactory } from "src/app/components/shared/zx-edit-table-row-buttons/zx-edit-table-row-buttons.table-adapter";
import { ZxDateTimeDisplayComponent } from "src/app/components/shared/zx-date-time-display/zx-date-time-display.component";
import { TitleService } from "src/app/services/title.service";
import { PushUpdatesService, CHANNELS, REFRESH_RATE_LIMIT } from "src/app/services/push-updates.service";
import {
    LiveEventFilter,
    TypeFilter
} from "src/app/components/live-events-filter-form/live-events-filter-form.component";
import { TourService } from "ngx-ui-tour-md-menu";
import { SourceThumbnailComponent } from "src/app/pages/sources/source/source-thumbnail/source-thumbnail.component";
import { LiveEventSourcesStatusColumnComponent } from "../live-event-sources-status-column/live-event-sources-status-column.component";
import {
    IconColumnComponent,
    IconTypes
} from "src/app/components/shared/table-list/tables-components/icon-column/icon-column.component";
import { TourSteps } from "src/app/constants/tour-steps";
import { LiveEventActions } from "src/app/components/shared/zx-action-buttons/zx-action-buttons.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LiveEventsConfirmationDialogComponent } from "src/app/components/shared/modals/live-events-confirmation-dialog/live-events-confirmation-dialog.component";
import { QueryParamsService } from "src/app/services/query-params.service";
import { dateFormats } from "src/app/services/time-zone.service";

interface State {
    searchTerm: string;
    fromDate: moment.Moment;
    toDate: moment.Moment;
    typeFilters: Partial<LiveEventFilter["stageStates"]>;
    resourceTags: number[];
    startsIn?: number;
}

export interface EventFrontData {
    countdown: string;
    nextStageName: string;
    stageName: string;
}

enum ActiveLiveEventColumnsDefTypes {
    OUTPUT = "thumbnail",
    SOURCES_STATUS = "sources_status",
    SLATES_STATUS = "slates_status",
    MODE = "mode",
    SLATED = "SLATED"
}

@Component({
    selector: "app-live-events-list",
    templateUrl: "./live-events-list.component.html",
    providers: [DatePipe]
})
export class LiveEventsListComponent implements OnInit, OnDestroy {
    @ViewChild(RouterOutlet) event: RouterOutlet;

    private eventsSubscription: Subscription;
    private refreshSubscription: Subscription;
    private refreshEventSubscription: Subscription;
    private routeSubscription: Subscription;

    liveEventId: number = null;
    urls = Constants.urls;
    resourceTags: Tag[];
    userPermissions: UserPermissions;
    refreshing = false;
    liveEvents: LiveEvent[] = [];
    selectedRows: Array<LiveEvent> = [];
    private currentSortDirection: string;
    showMultiActions = true;
    fullLoad = false;
    get filterEventObservable() {
        return this.eventsBS$.asObservable();
    }

    private tourSteps = TourSteps.eventsList;

    tableColumnsSchema: TableSchema<KeyMap<LiveEvent & EventFrontData>>[] = [
        {
            header: this.translate.instant("NAME"),
            columnDef: "name",
            width: 160,
            visible: true,
            sticky: 1,
            component: ZxLiveEventNameComponent,
            assignComponentsInputs: assignComponentsLiveEventAdapter(this.liveEventId ? true : undefined),
            textValue: row => row.name,
            sortBy: row => row.name,
            valueToExport: row => row.name
        },
        {
            header: this.translate.instant("STATUS"),
            columnDef: "status",
            width: 160,
            visible: true,
            component: ZxStatusFullComponent,
            assignComponentsInputs: assignComponentsStatusInputsFactory(),
            sortBy: row =>
                this.currentSortDirection === "asc"
                    ? row._sortData.sortableStatusAsc
                    : row._sortData.sortableStatusDesc,
            valueToExport: row =>
                this.stp.transform(row).length > 0 ? this.translate.instant(this.stp.transform(row)) : ""
        },
        {
            header: this.translate.instant("START_TIME"),
            columnDef: "start_time",
            width: 160,
            visible: true,
            component: ZxDateTimeDisplayComponent,
            assignComponentsInputs: assignDateTimeDisplayInputsFactory<LiveEvent & EventFrontData>(
                row => row.start_time,
                dateFormats.longDateTimeSeconds
            ),
            sortBy: row => new Date(row.start_time).getTime(),
            valueToExport: row => row.start_time
        },
        {
            header: this.translate.instant("STAGE"),
            columnDef: "stage",
            width: 160,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<LiveEvent & EventFrontData>(
                row => row.stageName,
                row => row.stageName,
                () => true
            ),
            sortBy: row => row.stageName,
            valueToExport: row => this.desankePipe.transform(row.stageName)
        },
        {
            header: this.translate.instant("COUNTDOWN"),
            columnDef: "countdown",
            width: 160,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<LiveEvent & EventFrontData>(
                // TODO: AMIT whats is the right value here this invalid date string came from CountdownService been used in livevent service
                row => (row.countdown === "Invalid date" ? "-" : row.countdown),
                // TODO: AMIT whats is the right value here
                row => (row.countdown === "Invalid date" ? "-" : row.countdown),
                row => row.nextStageName !== "-"
            )
        },
        {
            header: this.translate.instant("NEXT_STAGE"),
            columnDef: "next_stage",
            width: 160,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<LiveEvent & EventFrontData>(
                row => row.nextStageName,
                row => row.nextStageName,
                row => row.nextStageName !== "off"
            ),
            sortBy: row => row.nextStageName
        },
        {
            header: this.translate.instant("ACTIONS"),
            columnDef: "actions",
            width: 120,
            visible: true,
            align: "right",
            stickyToLast: true,
            component: ZxEditTableRowButtonsComponent,
            assignComponentsInputs: assignEditTableRowInputsFactory<LiveEvent, Promise<void>>({
                canDeleteCallBack: this.canDeleteEvent.bind(this),
                canEditCallBack: this.canEditEvent.bind(this),
                canCloneCallBack: () => true,
                canToggleSlateCallBack: () => !!this.state.typeFilters.active,
                toggleSlateRef: liveEvent => this.es.IsSlateLocked(liveEvent).then(() => this.refresh()),
                editRef: this.edit.bind(this),
                cloneRef: this.clone.bind(this),
                deleteRef: this.delete.bind(this)
            })
        },
        {
            header: this.translate.instant("TAGS"),
            columnDef: "access_tags",
            width: 60,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<LiveEvent & EventFrontData>(
                row => row.resourceTags?.map(tag => tag.name).toString(),
                row => row.resourceTags?.map(tag => tag.name).toString(),
                () => true
            ),
            valueToExport: row => row.resourceTags?.map(tag => tag.name).join(", ")
        },
        {
            header: this.translate.instant("STAGE_EXPORT_COLUMN_HEADER"),
            columnDef: this.translate.instant("STAGE_EXPORT_COLUMN_HEADER"),
            width: 100,
            visible: false,
            hideFromColumnChooser: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<LiveEvent & EventFrontData>(
                row =>
                    row.actions
                        .map(stage => `(${stage.name}, ${stage.duration} minutes, ${!!stage.force_channel_slate})`)
                        .join(", "),
                row =>
                    row.actions
                        .map(stage => `(${stage.name}, ${stage.duration} minutes, ${!!stage.force_channel_slate})`)
                        .join(", "),
                () => true
            ),
            valueToExport: row =>
                row.actions
                    .map(stage => `(${stage.name}, ${stage.duration} minutes, ${!!stage.force_channel_slate})`)
                    .join(", ")
        }
    ];
    activeEventTableSchema = [
        ...this.tableColumnsSchema.slice(0, 2),
        // Add the new columns in specific position
        {
            header: this.translate.instant("OUTPUT"),
            columnDef: ActiveLiveEventColumnsDefTypes.OUTPUT,
            width: 110,
            visible: true,
            component: SourceThumbnailComponent,
            assignComponentsInputs: (
                statusComponentRef: ComponentRef<SourceThumbnailComponent>,
                row: KeyMap<LiveEvent & EventFrontData>
            ) => {
                const sourceThumbnailCompRef = statusComponentRef.instance;
                sourceThumbnailCompRef.source = row?.failoverChannels[0]?.failoverSource;
                sourceThumbnailCompRef.refreshInterval = 14000;
                sourceThumbnailCompRef.bordered = false;
                sourceThumbnailCompRef.info = false;
                sourceThumbnailCompRef.overlay = false;
                sourceThumbnailCompRef.bitrateOverlay = false;
            }
        },
        {
            header: this.translate.instant("SOURCES"),
            columnDef: ActiveLiveEventColumnsDefTypes.SOURCES_STATUS,
            width: 80,
            visible: true,
            component: LiveEventSourcesStatusColumnComponent,
            assignComponentsInputs: (
                statusComponentRef: ComponentRef<LiveEventSourcesStatusColumnComponent>,
                row: KeyMap<LiveEvent & EventFrontData>
            ) => {
                const sourceThumbnailCompRef = statusComponentRef.instance;
                const sources = _.flatten(
                    row.failoverChannels.map(({ failoverSource }) => failoverSource.failoverSources)
                );
                const sourcesWithZeroPriority = sources.filter(source => source.priority);
                sourceThumbnailCompRef.sources = sourcesWithZeroPriority.map(({ source }) => source);
            }
        },
        {
            header: this.translate.instant("SLATES"),
            columnDef: ActiveLiveEventColumnsDefTypes.SLATES_STATUS,
            width: 80,
            visible: true,
            component: LiveEventSourcesStatusColumnComponent,
            assignComponentsInputs: (
                statusComponentRef: ComponentRef<LiveEventSourcesStatusColumnComponent>,
                row: KeyMap<LiveEvent & EventFrontData>
            ) => {
                const sourceThumbnailCompRef = statusComponentRef.instance;
                const sources = _.flatten(
                    row.failoverChannels.map(({ failoverSource }) => failoverSource.failoverSources)
                );
                const sourcesWithZeroPriority = sources.filter(source => source.priority <= 0);
                sourceThumbnailCompRef.sources = sourcesWithZeroPriority.map(({ source }) => source);
            }
        },
        {
            header: this.translate.instant("SLATED"),
            columnDef: ActiveLiveEventColumnsDefTypes.SLATED,
            width: 80,
            visible: true,
            component: IconColumnComponent,
            assignComponentsInputs: (
                statusComponentRef: ComponentRef<IconColumnComponent>,
                row: KeyMap<LiveEvent & EventFrontData>
            ) => {
                const componentRef = statusComponentRef.instance;
                componentRef.iconType = IconTypes.CHECK;
                componentRef.showIcon = row.is_slate_locked;
            }
        },
        ...this.tableColumnsSchema.slice(2)
    ];

    stagesFilter: TypeFilter[] = [
        { text: "Completed", color: "primary", key: "off", enabled: true },
        { text: "ACTIVE", color: "primary", key: "active", enabled: false },
        { text: "Scheduled", color: "primary", key: "pending", enabled: false }
    ];

    private filter = {
        events: true
    };

    private _filteredEvents: LiveEvent[];
    private eventsBS$ = new BehaviorSubject<(LiveEvent & EventFrontData)[]>([]);
    private filteredEventsBS$ = new BehaviorSubject<LiveEvent[]>([]);
    state: State = {
        searchTerm: "",
        fromDate: null,
        toDate: null,
        typeFilters: {},
        resourceTags: [],
        startsIn: 0
    };

    constructor(
        public sharedService: SharedService,
        private userService: UsersService,
        private modalService: ModalService,
        public mixpanelService: MixpanelService,
        private translate: TranslateService,
        private es: LiveEventsService,
        private detailsService: LiveEventDetailsService,
        private stp: StatusTextPipe,
        private desankePipe: DesnakePipe,
        private router: Router,
        private titleService: TitleService,
        private pushUpdatesService: PushUpdatesService,
        private ngbModal: NgbModal,
        public tourService: TourService,
        public queryParamsService: QueryParamsService
    ) {
        // Set Title
        this.titleService.setTitle("LIVE_EVENTS", "");
        //
        this.routeSubscription = this.router.events
            .pipe(filter(event => event instanceof ActivationEnd && event.snapshot.children?.length === 0))
            .subscribe((event: ActivationEnd) => {
                if (event.snapshot.params && event.snapshot.params.id) {
                    this.liveEventId = parseInt(event.snapshot.params.id, 10);
                } else {
                    this.liveEventId = null;
                }
            });
    }

    async ngOnInit() {
        this.eventsSubscription = this.es.liveEvents$.subscribe(liveEvents => {
            this.liveEvents = this.liveEvents?.filter(liveEvent => liveEvents.find(i => i.id === liveEvent.id));
            // update existing events from the events list
            for (const liveEvent of liveEvents) {
                const existingEvent = this.liveEvents.find(eventItem => eventItem.id === liveEvent.id);
                if (!existingEvent) this.liveEvents.push(liveEvent);
                else Object.assign(existingEvent, liveEvent);
            }

            if (this.liveEvents) {
                this.prepTableData();
            }
        });

        firstValueFrom(this.sharedService.getResourceTagsByType("live_events")).then(
            tags => (this.resourceTags = tags)
        );
        firstValueFrom(this.userService.userPermissions).then(permissions => (this.userPermissions = permissions));

        // local storage
        document.getElementById("left-container").style.flexBasis = localStorage.getItem("list-panel-width");
        this.searchTerm = localStorage.getItem("event.searchTerm") ? localStorage.getItem("event.searchTerm") : "";

        // HOTFIX: Add refresh the events list every minute du to the push updates not working with source updates
        // TODO: Decide if we need to refresh the events list every minute and drop the pushUpdatesService or add the liveEvent.source changes to the push updates
        this.refreshEventSubscription = interval(60000).subscribe(() => this.refresh());

        this.refreshSubscription = this.pushUpdatesService
            .subscribeChannel({ name: CHANNELS.live_events, objectId: null })
            .pipe(auditTime(REFRESH_RATE_LIMIT)) // delay of 5 seconds, In case of consecutive publications, invoke refresh once every five seconds
            .subscribe(() => this.refresh());

        this.tourService.initialize(this.tourSteps);

        this.refresh().then(() => {
            this.fullLoad = true;
        }); // make full (non-fast, non-filtered) request
    }

    ngOnDestroy(): void {
        this.eventsSubscription.unsubscribe();
        this.refreshSubscription.unsubscribe();
        this.routeSubscription.unsubscribe();
        this.refreshEventSubscription.unsubscribe();
    }

    private getSelectedStageFilter(): { [key in TypeFilter["key"]]: boolean } {
        return this.stagesFilter.reduce<{ off: boolean; active: boolean; pending: boolean }>(
            (acc, stage) => {
                acc[stage.key] = !!stage.enabled;
                return acc;
            },
            { off: false, active: false, pending: false }
        );
    }

    async refresh() {
        this.refreshing = true;
        await firstValueFrom(this.es.refreshLiveEvents(true, false));
        this.refreshing = false;
    }

    onSort(s: string) {
        this.currentSortDirection = s;
    }

    selectRow = (liveEvent: LiveEvent) => {
        this.router.navigate([Constants.urls.liveevents, liveEvent.id]);
    };

    selectedRowsChange(_selectedRows: LiveEvent[]) {
        const allActive = this.selectedRows.every(row => this.detailsService.isEventActive(row));
        this.showMultiActions = !!allActive;
    }

    canEditEvent(liveEvent: LiveEvent) {
        return this.sharedService.canEditZixiObject(liveEvent, this.resourceTags, this.userPermissions);
    }

    canDeleteEvent(liveEvent: LiveEvent) {
        return (
            !this.detailsService.isEventActive(liveEvent) &&
            this.sharedService.canEditZixiObject(liveEvent, this.resourceTags, this.userPermissions)
        );
    }

    async multiAction(action: string, func: (liveEvent: LiveEvent) => Promise<unknown>) {
        const result = await this.modalService.confirmMultiple(action, "LIVE_EVENT", this.selectedRows, func);
        if (result.actionTaken) {
            this.mixpanelService.sendEvent(this.translate.instant(action).toLowerCase() + " multiple events");
            if (action === "DELETE") this.selectedRows = [];
        }
    }

    multiDelete() {
        this.multiAction("DELETE", async (liveEvent: LiveEvent) => {
            return this.es.deleteLiveEvent(liveEvent);
        });
    }

    async multiLiveEventsActions(action: LiveEventActions) {
        const modal = this.ngbModal.open(LiveEventsConfirmationDialogComponent, {
            backdrop: "static",
            centered: true,
            size: "lg"
        });

        modal.componentInstance.liveEvents = _.cloneDeep(this.selectedRows);
        modal.componentInstance.actionMode = action;
        modal.result.then(() => {
            this.refresh();
        });
    }

    edit(liveEvent: LiveEvent): void {
        this.router.navigate([Constants.urls.liveevents, liveEvent.id, "edit"]);
    }

    clone(liveEvent: LiveEvent): void {
        this.router.navigate([Constants.urls.liveevents, liveEvent.id, "clone"]);
    }

    async delete(liveEvent: LiveEvent) {
        await this.modalService.confirm(
            "DELETE",
            "LIVE_EVENT",
            async () => {
                const id = liveEvent.id;
                const result = await this.es.deleteLiveEvent(liveEvent);
                if (result) {
                    this.mixpanelService.sendEvent("delete live event", { id });
                } else return false;
            },
            liveEvent.name
        );
    }

    get searchTerm() {
        return this.state.searchTerm;
    }
    set searchTerm(searchTerm: string) {
        this.setSearchTerm(searchTerm);
    }
    private _set(patch: Partial<State>) {
        Object.assign(this.state, patch);
        this.prepTableData();
    }

    get filteredEvents() {
        return this._filteredEvents;
    }

    set filteredEvents(filteredEvents: LiveEvent[]) {
        this._filteredEvents = _.cloneDeep(filteredEvents);
        this.filteredEventsBS$.next(this._filteredEvents);
    }

    public prepTableData() {
        if (this.liveEvents.length) {
            let events: LiveEvent[] = null;

            events = [...this.liveEvents];

            // 2. filter
            events = events.filter(liveEvent => {
                if (this.filter.events) return liveEvent;
            });
            events = events.filter(this.eventFilter);
            this.filteredEvents = events;

            this.eventsBS$.next(
                events.map(liveEvent => {
                    const next_stage = this.detailsService.nextStageAction(liveEvent.actions, liveEvent.stage_id);
                    const countdown = this.countDownToNextStage(liveEvent, next_stage?.name || "off");

                    const nextStageName = this.getStageName(next_stage?.name || "off");
                    const currentStage = this.detailsService.getActiveAction(liveEvent);
                    const stageName = this.getStageName(currentStage?.name || "pending");

                    return Object.assign(liveEvent, {
                        countdown: stageName === "off" ? "-" : countdown,
                        nextStageName: stageName === "off" ? "-" : nextStageName,
                        stageName
                    });
                })
            );
        }
    }

    eventFilter = (liveEvent: LiveEvent) => {
        if (this.state.searchTerm) {
            const searchMatch = this.sharedService.matches(liveEvent, this.state.searchTerm, () =>
                liveEvent.name.toLowerCase().includes(this.state.searchTerm.toLowerCase())
            );
            if (!searchMatch) return false;
        }

        if (this.state.fromDate || this.state.toDate) {
            const startTimeMoment = moment(liveEvent.start_time);

            let dateMatch = true;
            if (!this.state.toDate) {
                dateMatch = startTimeMoment.isAfter(this.state.fromDate);
            } else if (!this.state.fromDate) {
                dateMatch = startTimeMoment.isBefore(this.state.toDate);
            } else {
                dateMatch = startTimeMoment.isBetween(this.state.fromDate, this.state.toDate);
            }

            if (!dateMatch) return false;
        }

        if (this.state.typeFilters) {
            if (this.state.typeFilters.active === false && this.detailsService.isEventActive(liveEvent)) return false;

            if (this.state.typeFilters.pending === false && this.detailsService.isEventPending(liveEvent)) return false;

            if (this.state.typeFilters.off === false && this.detailsService.isEventOff(liveEvent)) return false;

            // When a user choose active events we allow him to see the close pending events also
            if (
                this.state.typeFilters.active &&
                !this.detailsService.isEventPending(liveEvent) &&
                this.state.startsIn
            ) {
                const diff = moment(liveEvent.start_time).diff(moment(), "minutes");
                if (diff > this.state.startsIn) return false;
            }
        }

        if (this.state.resourceTags?.length > 0) {
            if (!liveEvent.resourceTags.find(tag => this.state.resourceTags.includes(tag.id))) return false;
        }

        return true;
    };

    countDownToNextStage(liveEvent: LiveEvent, nextStageName: STAGE): string {
        const stage = this.detailsService.getActiveAction(liveEvent);
        const nextStage = liveEvent.actions?.find(s => s.name.toLowerCase() === nextStageName.toLowerCase());

        const diff = moment(nextStage?.scheduled_time).diff(moment(stage?.scheduled_time ?? moment()), "seconds");

        return moment.duration(diff, "seconds").humanize();
    }

    // filter form
    async onFilterApply(filter: LiveEventFilter) {
        this.setFilters({
            ...filter,
            searchTerm: filter.msgFilter || "",
            typeFilters: filter.stageStates
        });

        if (!this.fullLoad) {
            const selectedStageFilter = this.getSelectedStageFilter();
            await firstValueFrom(
                this.es
                    .refreshLiveEvents(true, selectedStageFilter.active ? false : true, selectedStageFilter)
                    .pipe(take(1))
            );
        }

        this.prepTableData();
    }

    onFilterReset() {
        this.setFilters({
            searchTerm: "",
            fromDate: null,
            toDate: null,
            typeFilters: {},
            resourceTags: []
        });

        this.prepTableData();
    }

    setFilters(filter: State) {
        this.state = { ...this.state, ...filter };
        this.setSearchTerm(this.state.searchTerm);
    }

    setSearchTerm(searchTerm: string) {
        this._set({ searchTerm });
        localStorage.setItem("event.searchTerm", searchTerm);
    }

    getStageName(stageCode: STAGE) {
        const columnValue = this.stagesFilter.find(f => f.key === stageCode)?.text ?? "";
        if (columnValue) return columnValue;
        return stageCode.charAt(0).toUpperCase() + stageCode.slice(1);
    }
}
