import {
    Component,
    Input,
    OnInit,
    Output,
    EventEmitter,
    ViewChild,
    OnChanges,
    SimpleChanges,
    inject
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { Stage } from "../../pages/live-event-form/live-event-form.component";
import _ from "lodash";
import { dateFormats } from "src/app/services/time-zone.service";
import moment from "moment";
import { ModalService } from "src/app/components/shared/modals/modal.service";

@Component({
    selector: "zx-live-event-stage-table",
    templateUrl: "./live-event-stage-table.component.html"
})
export class LiveEventStageTableComponent implements OnInit, OnChanges {
    @ViewChild("form") form: NgForm;
    @Input() stages: Stage[] = [];
    @Input() clips: { name: string; url: string }[] = [];
    @Input() submitted: boolean;
    @Input() isStagingAuto = true;
    dateFormats = dateFormats;
    @Output() stagesChange = new EventEmitter<Stage[]>();
    private modalService = inject(ModalService);

    duplicateNamesIndex: number[] = [];
    olderStartTimeIndex: number[] = [];
    durations: number[] = [];

    ngOnChanges(changes: SimpleChanges) {
        if (changes.isStagingAuto && !changes.isStagingAuto.firstChange) {
            this.stages.forEach(stage => (stage.auto_mode = this.isStagingAuto));
        }
        if (changes.stages) this.reCalculateDuration();
    }

    ngOnInit() {
        this.validateAndEmitStage(true);
    }

    private reCalculateDuration() {
        for (let i = 0; i < this.stages.length; i++) {
            const stage = this.stages[i];
            const nextStage = this.stages[i + 1];
            const stageScheduleTime = new Date(stage.scheduled_time).getTime();
            const nextStageScheduleTime = new Date(nextStage?.scheduled_time).getTime();

            if (stage.scheduled_time && nextStage?.scheduled_time && stageScheduleTime < nextStageScheduleTime) {
                this.durations[i] = nextStageScheduleTime - stageScheduleTime;
            } else {
                this.durations[i] = null;
            }
        }
    }

    onStartTimeChange() {
        this.reCalculateDuration();
        this.validateAndEmitStage();
    }

    addStage() {
        const newStage = {
            name: null,
            force_channel_slate: false,
            clip_id: null,
            auto_mode: this.isStagingAuto,
            scheduled_time: null
        };

        // Insert the new stage at the index one before the last one
        const insertIndex = this.stages.length - 1;
        this.stages.splice(insertIndex, 0, newStage);

        this.reCalculateDuration();
        this.stagesChange.emit(this.stages);
    }

    async openDatePicker(stage: Stage) {
        const result = await this.modalService.singleDatepicker({ label: "SELECT_DATE" });
        if (result) {
            stage.scheduled_time = result;
            this.onStartTimeChange();
        }
    }

    deselectStage(deselectIndex: number) {
        this.stages = this.stages.filter((_, index) => index !== deselectIndex);
        this.reCalculateDuration();
        this.validateAndEmitStage(true);
    }

    validateAndEmitStage(nameChanged?: boolean) {
        if (nameChanged) {
            this.findDuplicateNamesIndex();
            setTimeout(() => {
                for (let i = 0; i < this.stages.length; i++) {
                    const stageNameControl = this.form.form.controls[`stageName_${i}`];

                    if (this.duplicateNamesIndex.includes(i)) {
                        stageNameControl.setErrors({ notUnique: true });
                    } else if (stageNameControl.value === null) {
                        stageNameControl.setErrors({ required: true });
                    } else {
                        stageNameControl.setErrors(null);
                    }
                }
            });
        }
        this.findOlderStartTimes();
        setTimeout(() => {
            for (let i = 0; i < this.stages.length; i++) {
                const startTimeControl = this.form.form.controls[`startTime_${i}`];

                if (this.olderStartTimeIndex.includes(i)) startTimeControl.setErrors({ olderTime: true });
                if (!startTimeControl.value) startTimeControl.setErrors({ required: true });
                if (!this.olderStartTimeIndex.includes(i) && startTimeControl.value) startTimeControl.setErrors(null);
            }
        });
        this.stagesChange.emit(this.stages);
    }

    private findDuplicateNamesIndex() {
        this.duplicateNamesIndex = [];
        for (const [index, stage] of this.stages.entries()) {
            if (stage.name !== null) {
                for (let i = index + 1; i < this.stages.length; i++) {
                    const otherStage = this.stages[i];
                    if (stage.name === otherStage.name && index !== i) {
                        this.duplicateNamesIndex.push(...[index, i]);
                    }
                }
            }
        }
        this.duplicateNamesIndex = [...new Set(this.duplicateNamesIndex)];
    }

    private findOlderStartTimes() {
        this.olderStartTimeIndex = [];
        let stageBeforeStartTime = new Date();
        for (let index = 0; index < this.stages.length; index++) {
            const currentStartTime = this.stages[index].scheduled_time;

            if (moment(currentStartTime).isBefore(moment(stageBeforeStartTime))) this.olderStartTimeIndex.push(index);

            stageBeforeStartTime = currentStartTime;
        }
    }
}
