<form id="form" #form="ngForm">
    <div class="form-group">
        <label for="stages">{{ "STAGES" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon></label>
        <div>
            <table class="table table-sm bordered m-0">
                <thead>
                    <tr>
                        <th scope="col" style="width: 50%">{{ "NAME" | translate }}</th>
                        <th scope="col" class="w-190px ellipsis" title="{{ 'START_TIME' | translate }}">
                            {{ "START_TIME" | translate }}
                        </th>
                        <th scope="col" class="w-100px ellipsis" title="{{ 'DURATION' | translate }}">
                            {{ "DURATION" | translate }}
                        </th>

                        <th scope="col" class="w-60px ellipsis" title="{{ 'FORCE' | translate }} ({{ 'SLATE' | translate }})">
                            {{ "FORCE" | translate }} {{ "SLATE" | translate }}
                        </th>
                        <th scope="col" class="w-60px ellipsis" title="{{ 'AUTO_MODE' | translate }}">
                            {{ "AUTO_MODE" | translate }}
                        </th>
                        <th scope="col" class="maxw-140px ellipsis" title="{{ 'PRE_ROLL_CLIP' | translate }}">
                            {{ "PRE_ROLL_CLIP" | translate }} ({{ "OPTIONAL" | translate }})
                        </th>
                        <th scope="col" class="w-40px text-end"></th>
                    </tr>
                </thead>
                <tbody *ngIf="stages.length > 0">
                    <tr *ngFor="let stage of stages; index as index">
                        <ng-container *ngIf="index < stages.length - 1">
                            <td>
                                <input
                                    type="text"
                                    placeholder="{{ 'STAGE_NAME' | translate }}"
                                    id="stageName_{{ index }}"
                                    name="stageName_{{ index }}"
                                    [(ngModel)]="stage.name"
                                    required
                                    class="form-control form-control-sm form-control-xs"
                                    [ngClass]="{ 'is-invalid': submitted && form.controls['stageName_' + index]?.errors }"
                                    (change)="validateAndEmitStage(true)"
                                    [disabled]="!!stage.actual_time"
                                />
                            </td>
                            <td class="input-group">
                                <input
                                    type="text"
                                    class="form-control form-control-sm form-control-xs minw-80px"
                                    id="startTime_{{ index }}"
                                    name="startTime_{{ index }}"
                                    placeholder="{{ 'SELECT_DATE' | translate }}"
                                    autocomplete="off"
                                    required
                                    [ngClass]="{ 'is-invalid': submitted && form.controls['startTime_' + index]?.errors }"
                                    [ngModel]="stage?.scheduled_time | date : 'yyyy-MM-dd HH:mm:ss'"
                                    [disabled]="!!stage.actual_time"
                                    (keydown)="$event.preventDefault()"
                                />
                                <button
                                    *ngIf="stage.scheduled_time"
                                    type="button"
                                    class="btn btn-xs btn-outline-primary"
                                    title="{{ 'CLEAR_DATE' | translate }}"
                                    (click)="stage.scheduled_time = null; onStartTimeChange()"
                                    [disabled]="!!stage.actual_time"
                                    [ngStyle]="{ 'font-size': '0.5rem' }"
                                >
                                    <fa-icon icon="times" size="xs"></fa-icon>
                                </button>
                                <button
                                    *ngIf="!stage.scheduled_time"
                                    type="button"
                                    class="btn btn-xs btn-outline-primary"
                                    title="{{ 'SELECT_DATE' | translate }}"
                                    (click)="openDatePicker(stage)"
                                    [disabled]="!!stage.actual_time"
                                    [ngStyle]="{ 'font-size': '0.5rem' }"
                                >
                                    <fa-icon icon="calendar-alt" size="xs"></fa-icon>
                                </button>
                            </td>
                            <td>
                                <span>{{ durations[index] ? (durations[index] | timestamp) : "-" }}</span>
                            </td>
                            <td>
                                <div class="form-check d-flex justify-content-center">
                                    <input
                                        type="checkbox"
                                        class="form-check-input"
                                        id="forceslate_{{ index }}"
                                        name="forceslate_{{ index }}"
                                        [(ngModel)]="stage.force_channel_slate"
                                        (change)="validateAndEmitStage()"
                                        [disabled]="!!stage.actual_time"
                                    />
                                </div>
                            </td>
                            <td>
                                <div class="form-check d-flex justify-content-center">
                                    <input
                                        type="checkbox"
                                        class="form-check-input"
                                        id="auto_mode{{ index }}"
                                        name="auto_mode{{ index }}"
                                        [(ngModel)]="stage.auto_mode"
                                        (change)="validateAndEmitStage()"
                                        [disabled]="!!stage.actual_time"
                                    />
                                </div>
                            </td>
                            <td>
                                <ng-select
                                    class="form-control form-control-sm form-control-xs"
                                    name="dropdown_{{ index }}"
                                    [items]="clips | map : 'name' | filter : '*'"
                                    [clearable]="true"
                                    [(ngModel)]="stage.clip_id"
                                    [disabled]="!index || !!stage.actual_time"
                                    (change)="validateAndEmitStage()"
                                ></ng-select>
                            </td>
                            <td class="text-end">
                                <button
                                    type="button"
                                    class="btn btn-sm btn-xs btn-round-xs btn-outline-secondary"
                                    [disabled]="stage.actual_time"
                                    (click)="deselectStage(index)"
                                >
                                    <fa-icon icon="minus" size="sm"></fa-icon>
                                </button>
                            </td>
                        </ng-container>
                    </tr>
                </tbody>
                <tbody>
                    <tr>
                        <td>
                            <input
                                type="text"
                                [ngModel]="'off'"
                                id="stageName_{{ stages.length - 1 }}"
                                name="stageName_{{ stages.length - 1 }}"
                                class="form-control form-control-sm form-control-xs"
                                [ngClass]="{ 'is-invalid': submitted && form.controls['stageName_' + (stages.length - 1)]?.errors }"
                                [disabled]="true"
                            />
                        </td>
                        <td class="input-group">
                            <input
                                type="text"
                                class="form-control form-control-sm form-control-xs minw-80px"
                                id="startTime_{{ stages.length - 1 }}"
                                name="startTime_{{ stages.length - 1 }}"
                                placeholder="{{ 'SELECT_DATE' | translate }}"
                                autocomplete="off"
                                required
                                [ngClass]="{ 'is-invalid': submitted && form.controls['startTime_' + (stages.length - 1)]?.errors }"
                                [ngModel]="stages.at(-1)?.scheduled_time | date : 'yyyy-MM-dd HH:mm:ss'"
                                [disabled]="!!stages.at(-1)?.actual_time"
                                (keydown)="$event.preventDefault()"
                            />
                            <button
                                *ngIf="stages.at(-1)?.scheduled_time"
                                type="button"
                                class="btn btn-xs btn-outline-primary"
                                title="{{ 'CLEAR_DATE' | translate }}"
                                (click)="stages.at(-1).scheduled_time = null; onStartTimeChange()"
                                [disabled]="!!stages.at(-1)?.actual_time"
                                [ngStyle]="{ 'font-size': '0.5rem' }"
                            >
                                <fa-icon icon="times" size="xs"></fa-icon>
                            </button>
                            <button
                                *ngIf="!stages.at(-1)?.scheduled_time"
                                type="button"
                                class="btn btn-xs btn-outline-primary"
                                title="{{ 'SELECT_DATE' | translate }}"
                                (click)="openDatePicker(stages.at(-1))"
                                [disabled]="!!stages.at(-1)?.actual_time"
                                [ngStyle]="{ 'font-size': '0.5rem' }"
                            >
                                <fa-icon icon="calendar-alt" size="xs"></fa-icon>
                            </button>
                        </td>
                        <td></td>
                        <td></td>
                        <td colspan="2" class="text-end">
                            <button type="button" class="btn btn-sm btn-xs btn-round-xs btn-primary" (click)="addStage()">
                                {{ "ADD" | translate }} <fa-icon icon="plus" size="sm"></fa-icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="submitted && duplicateNamesIndex.length" class="status-error mb-3">{{ "STAGE_NAME" | translate }} {{ "MUST_BE_UNIQUE" | translate }}.</div>
        <div *ngIf="submitted && olderStartTimeIndex.length" class="status-error mb-3">
            {{ "START_TIME" | translate }} {{ "MUST_BE_NEWER_THEN_BEFORE_STAGES" | translate }}.
        </div>
        <div *ngIf="submitted && stages.length === 0" class="status-error mb-3">{{ "AT_LEAST_1_STAGE_IS_REQUIRED" | translate }}.</div>
    </div>
</form>
